import { useMemo, useEffect } from "react";
import { useIntl } from "react-intl";

import { GameCategoryAPI } from "@/api";
import {
  CategoryCreated,
  CategoryEdited,
  CategoryDeleted,
  OpenEditCategoryModal,
  OpenNewCategoryModal,
} from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

export function useRubricatorTableState() {
  const intl = useIntl();

  const { eventBusService } = useGlobalStore();

  const query = useMemo(() => {
    const query = new Query(GameCategoryAPI.getAll);
    query.submit({});
    return query;
  }, []);

  useEffect(() => {
    const listener = () => {
      query.submit({});
    };

    eventBusService.subscribe(CategoryCreated, listener);
    eventBusService.subscribe(CategoryEdited, listener);
    eventBusService.subscribe(CategoryDeleted, listener);
    return () => {
      eventBusService.unsubscribe(CategoryCreated, listener);
      eventBusService.unsubscribe(CategoryEdited, listener);
      eventBusService.unsubscribe(CategoryDeleted, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const categoies = query.data?.data.slice() || [];

  categoies.sort((a, b) => {
    const aPath = a.parentId ? `${a.parentId}_${a.id}` : `${a.id}_0`;
    const bPath = b.parentId ? `${b.parentId}_${b.id}` : `${b.id}_0`;
    return aPath.localeCompare(bPath);
  });

  function openNewCategoryModal(parentId: number | null) {
    eventBusService.publish(new OpenNewCategoryModal({ parentId }));
  }

  function openEditCategoryModal(id: number) {
    eventBusService.publish(new OpenEditCategoryModal({ id }));
  }

  return {
    intl,
    query,
    categoies,

    openNewCategoryModal,
    openEditCategoryModal,
  };
}

export type RubricatorTableState = ReturnType<typeof useRubricatorTableState>;
