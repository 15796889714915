import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Space } from "antd";
import { observer } from "mobx-react-lite";

import Page from "@/ui/_common_/page";

import { EditCategoryModal } from "./edit";
import { NewCategoryModal } from "./new";
import { RubricatorPageState } from "./page.state";
import { RubricatorTable } from "./table";

type Props = { state: RubricatorPageState };

export const RubricatorPageView = observer<Props>(({ state }) => {
  return (
    <Page title={"Rubricator"}>
      <NewCategoryModal />
      <EditCategoryModal />
      <Card size="small">
        <Space direction="vertical">
          <Row justify="end">
            <Col>
              <Button
                icon={<PlusOutlined />}
                onClick={() => state.openNewCategoryModal(null)}
              >
                {"Create"}
              </Button>
            </Col>
          </Row>
          <RubricatorTable />
        </Space>
      </Card>
    </Page>
  );
});
