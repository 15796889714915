import { Menu } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";

import { SearchParamKey } from "@/constants";
import { useGlobalStore } from "@/stores";
import {
  CalendarIcon,
  CashRegisterIcon,
  CategoryIcon,
  ClockIcon,
  CoinsIcon,
  DescendingSorting,
  DiceIcon,
  DollarEuroExchangeIcon,
  ExchangeEuroIcon,
  ExchangeMoneyEthereum,
  GiftIcon,
  HomeIcon,
  ImageIcon,
  InsuranceAgentIcon,
  LanguageIcon,
  LoyaltyCard,
  PeopleIcon,
  ProfitReportIcon,
  ReceiveDollarIcon,
  SearchIcon,
  SlotMachineIcon,
  SoccerIcon,
  StudyIcon,
  TicketIcon,
  UploadLinkDocumentIcon,
  UserMaleCircleIcon,
  WebsiteIcon,
  WinIcon,
  WordbookIcon,
} from "@/ui/_common_/icons";

import { usePanicItem } from "./use-panic-item";

export const iconStyle = { width: 20 };

function Sidebar() {
  const intl = useIntl();
  const location = useLocation();
  const { routerService, userStore, permissionsStore } = useGlobalStore();

  const [expandedSubmenuKey, setExpandedSubmenuKey] = useState(() => {
    if (routerService.location.pathname.startsWith("/reports")) {
      return "/reports";
    }
    if (routerService.location.pathname.startsWith("/handbook")) {
      return "/handbook";
    }
    return null;
  });

  const pathname = location.pathname;

  const selectedKey = useMemo(() => {
    if (pathname.startsWith("/kb")) {
      return "/kb";
    }
    return pathname;
  }, [pathname]);

  const search = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    for (const key of searchParams.keys()) {
      if (
        ![
          SearchParamKey.ClientId as string,
          SearchParamKey.AgentId as string,
          SearchParamKey.HallId as string,
          SearchParamKey.PlayerId as string,
        ].includes(key)
      ) {
        searchParams.delete(key);
      }
    }
    return searchParams.toString();
  }, [location]);

  const panicItem = usePanicItem();

  const items = (() => {
    const items: ItemType[] = [];

    if (permissionsStore.has("ViewClients")) {
      items.push({
        key: "/clients",
        icon: <UserMaleCircleIcon style={iconStyle} />,
        label: (
          <Link to={{ pathname: "/clients", search }}>
            {intl.formatMessage({ defaultMessage: "Clients" })}
          </Link>
        ),
      });
    }

    if (permissionsStore.has("ViewAgents")) {
      items.push({
        key: "/agents",
        icon: <InsuranceAgentIcon style={iconStyle} />,
        label: (
          <Link to={{ pathname: "/agents", search }}>
            {intl.formatMessage({ defaultMessage: "Agents" })}
          </Link>
        ),
      });
    }

    if (permissionsStore.has("ViewHalls")) {
      items.push({
        key: "/shops",
        icon: <HomeIcon style={iconStyle} />,
        label: (
          <Link to={{ pathname: "/shops", search }}>
            {intl.formatMessage({ defaultMessage: "Shops" })}
          </Link>
        ),
      });
    }

    if (userStore.user.role === "HallAdmin") {
      items.push({
        key: "/cashiers",
        icon: <ReceiveDollarIcon style={iconStyle} />,
        label: (
          <Link to={{ pathname: "/cashiers", search }}>
            {intl.formatMessage({ defaultMessage: "Cashiers" })}
          </Link>
        ),
      });
    }

    if (permissionsStore.has("ViewPlayers")) {
      items.push({
        key: "/players",
        icon: <PeopleIcon style={iconStyle} />,
        label: (
          <Link to={{ pathname: "/players", search }}>
            {intl.formatMessage({ defaultMessage: "Players" })}
          </Link>
        ),
      });
    }

    if (permissionsStore.has("ViewKiosks")) {
      items.push({
        key: "/kiosks",
        icon: <SlotMachineIcon style={iconStyle} />,
        label: (
          <Link to={{ pathname: "/kiosks", search }}>
            {intl.formatMessage({ defaultMessage: "Kiosks" })}
          </Link>
        ),
      });
    }

    if (permissionsStore.has("ViewReports")) {
      const children: ItemType[] = [];

      if (permissionsStore.has("ViewDailyProfitReport")) {
        children.push({
          key: "/reports/daily-profit",
          icon: <CalendarIcon style={iconStyle} />,
          label: (
            <Link to={{ pathname: "/reports/daily-profit", search }}>
              {intl.formatMessage({ defaultMessage: "Daily profit" })}
            </Link>
          ),
        });
      }

      children.push({
        key: "/reports/summary",
        icon: <ProfitReportIcon style={iconStyle} />,
        label: (
          <Link to={{ pathname: "/reports/summary", search }}>
            {intl.formatMessage({ defaultMessage: "Summary" })}
          </Link>
        ),
      });

      children.push({
        key: "/reports/history",
        icon: <ClockIcon style={iconStyle} />,
        label: (
          <Link to={{ pathname: "/reports/history", search }}>
            {intl.formatMessage({ defaultMessage: "History" })}
          </Link>
        ),
      });

      children.push({
        key: "/reports/financial",
        icon: <CoinsIcon style={iconStyle} />,
        label: (
          <Link to={{ pathname: "/reports/financial", search }}>
            {intl.formatMessage({ defaultMessage: "Financial" })}
          </Link>
        ),
      });

      children.push({
        key: "/reports/bonuses",
        icon: <LoyaltyCard style={iconStyle} />,
        label: (
          <Link to={{ pathname: "/reports/bonuses", search }}>
            {intl.formatMessage({ defaultMessage: "Bonuses" })}
          </Link>
        ),
      });

      children.push({
        key: "/reports/fortune-wheel",
        icon: <LoyaltyCard style={iconStyle} />,
        label: (
          <Link to={{ pathname: "/reports/fortune-wheel", search }}>
            {intl.formatMessage({ defaultMessage: "Fortune Wheel" })}
          </Link>
        ),
      });

      children.push({
        key: "/reports/jackpots",
        icon: <WinIcon style={iconStyle} />,
        label: (
          <Link to={{ pathname: "/reports/jackpots", search }}>
            {intl.formatMessage({ defaultMessage: "Jackpots" })}
          </Link>
        ),
      });

      if (permissionsStore.has("ViewGamesReport")) {
        children.push({
          key: "/reports/games",
          icon: <DiceIcon style={iconStyle} />,
          label: (
            <Link to={{ pathname: "/reports/games", search }}>
              {intl.formatMessage({ defaultMessage: "Games" })}
            </Link>
          ),
        });
      }

      children.push({
        key: "/reports/vouchers",
        icon: <TicketIcon style={iconStyle} />,
        label: (
          <Link to={{ pathname: "/reports/vouchers", search }}>
            {intl.formatMessage({ defaultMessage: "Vouchers" })}
          </Link>
        ),
      });

      if (permissionsStore.has("ViewSettlementsReport")) {
        children.push({
          key: "/reports/settlements",
          icon: <ExchangeEuroIcon style={iconStyle} />,
          label: (
            <Link to={{ pathname: "/reports/settlements", search }}>
              {intl.formatMessage({ defaultMessage: "Settlements" })}
            </Link>
          ),
        });
      }

      children.push({
        key: "/reports/shifts",
        icon: <CashRegisterIcon style={iconStyle} />,
        label: (
          <Link to={{ pathname: "/reports/shifts", search }}>
            {intl.formatMessage({ defaultMessage: "Shifts" })}
          </Link>
        ),
      });

      if (
        userStore.user.role === "Admin" ||
        userStore.user.role === "Manager" ||
        userStore.clientMode !== "normal"
      ) {
        children.push({
          key: "/reports/sweepstakes",
          icon: <ExchangeMoneyEthereum style={iconStyle} />,
          label: (
            <Link to={{ pathname: "/reports/sweepstakes", search }}>
              {intl.formatMessage({ defaultMessage: "Entries Changing" })}
            </Link>
          ),
        });
      }

      children.push({
        key: "/reports/sport",
        icon: <SoccerIcon style={iconStyle} />,
        label: (
          <Link to={{ pathname: "/reports/sport", search }}>
            {intl.formatMessage({ defaultMessage: "Sport Bets History" })}
          </Link>
        ),
      });

      items.push({
        key: "/reports",
        icon: <UploadLinkDocumentIcon style={iconStyle} />,
        label: intl.formatMessage({ defaultMessage: "Reports" }),
        children,
      });
    }

    if (permissionsStore.has("ViewHandbook")) {
      const children: ItemType[] = [];

      if (permissionsStore.has("ViewHandbookGames")) {
        children.push({
          key: "/handbook/games",
          icon: <DiceIcon style={iconStyle} />,
          label: <Link to="/handbook/games">Games</Link>,
        });
      }

      if (permissionsStore.has("ViewHandbookGamesOrder")) {
        children.push({
          key: "/handbook/games-order",
          icon: <DescendingSorting style={iconStyle} />,
          label: <Link to="/handbook/games-order">Games order</Link>,
        });
      }

      if (permissionsStore.has("ViewHandbookCurrencies")) {
        children.push({
          key: "/handbook/currencies",
          icon: <DollarEuroExchangeIcon style={iconStyle} />,
          label: <Link to="/handbook/currencies">Currencies</Link>,
        });
      }

      if (permissionsStore.has("ViewHandbookLanguages")) {
        children.push({
          key: "/handbook/languages",
          icon: <LanguageIcon style={iconStyle} />,
          label: <Link to="/handbook/languages">Languages</Link>,
        });
      }

      if (permissionsStore.has("ViewHandbookWebsites")) {
        children.push({
          key: "/handbook/websites",
          icon: <WebsiteIcon style={iconStyle} />,
          label: <Link to="/handbook/websites">Websites</Link>,
        });
      }

      if (permissionsStore.has("ViewHandbookJackpots")) {
        children.push({
          key: "/handbook/jackpots",
          icon: <WinIcon style={iconStyle} />,
          label: <Link to="/handbook/jackpots">Jackpots</Link>,
        });
      }

      if (permissionsStore.has("ViewHandbookBonuses")) {
        children.push({
          key: "/handbook/bonuses",
          icon: <GiftIcon style={iconStyle} />,
          label: <Link to="/handbook/bonuses">Bonuses</Link>,
        });
      }

      if (permissionsStore.has("ViewHandbookBanners")) {
        children.push({
          key: "/handbook/banners",
          icon: <ImageIcon style={iconStyle} />,
          label: <Link to="/handbook/banners">Lobby banners</Link>,
        });
      }

      if (permissionsStore.has("ViewHandbookRubricator")) {
        children.push({
          key: "/handbook/rubricator",
          icon: <CategoryIcon style={iconStyle} />,
          label: <Link to="/handbook/rubricator">Rubricator</Link>,
        });
      }

      if (permissionsStore.has("ViewHandbookSportPartners")) {
        children.push({
          key: "/handbook/sport-partners",
          icon: <SoccerIcon style={iconStyle} />,
          label: <Link to="/handbook/sport-partners">Sport Partners</Link>,
        });
      }

      items.push({
        key: "/handbook",
        icon: <StudyIcon style={iconStyle} />,
        label: "Handbook",
        children,
      });
    }

    items.push({
      key: "margin",
      style: { marginTop: "auto" },
    });

    if (permissionsStore.has("ViewGlobalSearch")) {
      items.push({
        key: "/search",
        icon: <SearchIcon style={iconStyle} />,
        label: (
          <Link to={{ pathname: "/search", search }}>
            {intl.formatMessage({ defaultMessage: "Search" })}
          </Link>
        ),
      });
    }

    if (
      userStore.user.role === "Cashier" ||
      userStore.user.role === "HallAdmin"
    ) {
      items.push(panicItem);
    }

    items.push({
      key: "/kb",
      icon: <WordbookIcon style={iconStyle} />,
      label: (
        <Link to={{ pathname: "/kb", search }}>
          {intl.formatMessage({ defaultMessage: "Knowledge base" })}
        </Link>
      ),
    });

    return items;
  })();

  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[selectedKey]}
      openKeys={expandedSubmenuKey ? [expandedSubmenuKey] : undefined}
      onOpenChange={(openKeys) => {
        if (!openKeys.length) {
          return setExpandedSubmenuKey(null);
        }
        const lastKey = openKeys[openKeys.length - 1];
        if (typeof lastKey !== "string") {
          return setExpandedSubmenuKey(null);
        }
        setExpandedSubmenuKey(lastKey);
      }}
      style={{ flex: 1, display: "flex", flexDirection: "column" }}
      items={items}
    />
  );
}

export default observer(Sidebar);
