import MethodBuilder from "../method-builder";
import { TPaging } from "../types";

type LocalizedStringMap = Record<string, string>;

type GameCategory = {
  id: number;
  names: LocalizedStringMap;
  parentId: number | null;
  isActive: boolean;
  order: number;
  tag: "header" | "menu" | "bottom" | null;
};

export class GameCategoryAPI {
  static getById = new MethodBuilder<{ id: number }, GameCategory>()
    .withName("GameCategory.getById")
    .build();

  static getAll = new MethodBuilder<
    { searchQuery?: string; orderBy?: ["id", "asc" | "desc"] },
    { data: GameCategory[]; total: number }
  >()
    .withName("GameCategory.getAll")
    .build();

  static create = new MethodBuilder<
    {
      names: LocalizedStringMap;
      isActive: boolean;
      order: number;
      parentId: number | null;
      tag: "header" | "menu" | "bottom" | null;
    },
    GameCategory
  >()
    .withName("GameCategory.create")
    .build();

  static update = new MethodBuilder<
    {
      id: number;
      isActive?: boolean;
      order?: number;
      tag?: "header" | "menu" | "bottom" | null;
    },
    void
  >()
    .withName("GameCategory.update")
    .build();

  static delete = new MethodBuilder<{ id: number }, void>()
    .withName("GameCategory.delete")
    .build();

  static filter = new MethodBuilder<
    { searchQuery?: string; paging?: TPaging },
    { data: GameCategory[]; total: number }
  >()
    .withName("GameCategory.filter")
    .build();
}
