import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { render } from "react-dom";
import Modal from "react-modal";
import "antd/dist/antd.css";
// TODO: integrate dark mode
// import "antd/dist/antd.dark.css";
import "./index.css";

import App from "./app";
import { IS_VERBOSE } from "./constants";
import { IntlProvider } from "./i18n/intl_provider";
import { StoresProvider } from "./stores";
import { Logger } from "./utilities";

import "./hljs";

if (IS_VERBOSE) {
  window.addEventListener("error", ({ error }) => {
    if (error) {
      Logger.error(error);
    }
  });
}

const environment =
  {
    localhost: "local",
    "cashier.realfast.ru": "dev",
    "cashier.templat.ru": "stage",
  }[window.location.hostname] ?? "prod";

Sentry.init({
  dsn: "https://4d6e5dadd1db420396da2cfe39f298fc@services-dev.kiosk.games/4",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment,
});

const root = document.querySelector("#root") as HTMLElement;
Modal.setAppElement(root);

render(
  <StoresProvider>
    <IntlProvider>
      <App />
    </IntlProvider>
  </StoresProvider>,
  root,
);
